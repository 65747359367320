import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./routes/home";
import { Suspense, lazy } from "react";

const Footer = lazy(() => import("./components/footer/footer"));
const SubPages = lazy(() => import("./routes/subPages"));

function App() {
  return (
    <Router basename="/">
      <div className="">
        <Suspense
          fallback={
            <div class="preloader clock text-center">
              <div class="organiaLoader">
                <div class="loaderO">
                  <span>V</span>
                  <span>E</span>
                  <span>D</span>
                  <span>A</span>
                  <span>S</span>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/*" element={<SubPages />} />
          </Routes>
        </Suspense>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
