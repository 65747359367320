import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// import Pages from './pages';

const HomeBanner = lazy(() => import("../components/banners/homeBanner"));
const About = lazy(() => import("../components/home1/about"));
const Services = lazy(() => import("../components/home1/services"));
const Home1Body = lazy(() => import("../components/home1/home1Body"));
const VideoPlayer = lazy(() => import("../components/home1/videoPlayer"));
const BlogArea = lazy(() => import("../components/home1/blogArea"));
const TopHeader = lazy(() => import("../components/headers/topHeader"));
const MiddleHeader = lazy(() => import("../components/headers/middleHeader"));
const NavBarHeader = lazy(() => import("../components/headers/navBarHeader"));

const Home = React.memo(() => {
  return (
    <>
      {/* <TopHeader /> */}
      <MiddleHeader />
      <NavBarHeader />
      <HomeBanner />
      <About />
      <Services />
      <Home1Body />
      {/* <VideoPlayer /> */}
      {/* <BlogArea /> */}
    </>
  );
});

export default Home;
